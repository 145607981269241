/* @import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css"; */
/* @import '../../node_modules/@syncfusion/ej2/material.css'; */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  /* overflow: hidden; */
}
