.profile_main {
  margin: 0px !important;
  height: 100vh;
}
.profile_hedaer {
  height: 130px !important;
  background-color: #1f5da0;
  padding: 0px 18px;
  display: flex;
  align-items: center;
}
.profile_hedaer .profile_top {
  display: flex;
  align-items: center;
}
.profile_hedaer svg {
  color: white;
  font-size: 30px !important;
  cursor: pointer;
}
.profile_top h3 {
  color: white;
  margin-top: 5px;
  margin-left: 15px;
}
.user_profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -80px !important;
}
.user_profile2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -60px !important;
}
.user_profile3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px !important;
}
.user_profile h2 {
  color: #1f5da0;
  margin: 15px 0px;
  font-size: 32px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
}
.user_profile button {
  border: 1px solid #1f5da0;
  outline: none;
  border-radius: 10px;
  color: white;
  padding: 10px 24px;
  background: #1f5da0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}
.user_profile button:hover {
  border: 1px solid #1f5da0;
  color: #1f5da0;
  background: white;
  transition: 0.4s all ease;
}
.user_info .name{
  display: flex;
  align-items: center;
  margin-left: 18px;
}
.user_info .edit {
  display: flex;
  align-items: center;
  /* padding: 3px 0px; */
  margin-left: 18px;
  justify-content: space-between;
}


.name h5 {
  color: rgba(29, 29, 29, 0.75);
  margin-left: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  font-size: 20px !important;
  margin-top: 10px !important;
}
.user_profile_new_div {
  position: relative;
   width: 131.18px;
  height: 131.18px;
  /*border-radius: 50%;
  background-size: cover; */
}

.user_profile_new_div .top_div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(29, 29, 29, 0.66);
  padding-top: 25px;
  border-radius: 50%;
  opacity: 0;
  /* justify-content: center; */
}
.user_profile_new_div:hover  .top_div ,.user_profile_new_div:focus  .top_div{
    /* display: block !important;
     */
     opacity: 1 !important;
}
.user_img_div div span {
  font-size: 8px;
  color: white;
  line-height: 1px;
}
.profile_pop{
    top: -15px !important;
}
.edit_icon{
    padding: 3px 4px;
    border-radius: 50%;
    border: .5px solid #1f5da0;
    /* margin-left: -50px !important; */
    cursor: pointer;
    margin: 10px;
}
.user_profile_new_div{
  width: 125px !important;
  height: 125px !important;
  border-radius: 50% !important;
  /* border: 1px solid red; */
}
.user_profile_new_div img{
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
  object-fit: cover;
}
.edit_name_div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}
.edit_name_div input{
  padding: 6px 5px;
  flex-basis: 45%;
  outline: none;
  border-radius: 8px;
  border:1px solid #1f5da0;
  color: #1f5da0;
}
/* .single_input{
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 100%;
} */
.single_input input{
  /* min-width: 300px!important; */
  padding: 6px 5px;
  /* flex-basis: 70% !important; */
  outline: none;
  border-radius: 8px;
  border:1px solid #1f5da0;
  color: #1f5da0;
  margin-left: 20px;
  min-width: 300px !important;
  
}
.file_upload{
  position: relative;
  width: 100%;
  cursor: pointer;
}
.file_upload input{
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 22;
  cursor: pointer;
}